.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type=text], textarea{
  width: 90% !important;
}
.inputRequired:after {
  content:"*";
  color:red;
}
.bg-grey{
  background-color: #938f8f;
}
.table-indicator{
  color: #8c8e93;
}
.btn-progress-indicator{
  margin-right:"8px"
}
.login-error{
  color:"red"
}

.hide-toast {
  animation: fade-out 1s;
 
}

.show-toast {
  animation: fade-in 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
@keyframes fade-out {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}

.fade-in{
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}
.fade-out{
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
  bottom: 40px;
  right: 240px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:22px;
}



/**Sticky Icon**/
.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right:0px;
  z-index: 10;
  border-radius:10px 0px 0px 10px;
  box-shadow: 2px 2px 3px #999;
  background:#1f88e5;
  &:hover{
    background:#186db7;
  }
}


.icon-bar a {
  display: block;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
  cursor:pointer;
  &:active{
    transform: translateY(3px);
  }
}


.blur {
  background-size:cover;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(2px);
  }
  .undo-delete-btn{
    position: absolute;
    top: 50%;
    left: 25%;
    border:1px solid #FFF;
    border-radius: 5px;
    cursor: pointer;
    color: #FFF;
    &:hover{
      border:1.5px solid #FFF;
    }
  }
 
  .undo-delete-btn span{
    padding: 10px;
  }



  .activeBannerPreview{
    width: 100%;
    height: auto;
  }
  
  

  /**Style changes***/
img.publicProductImg {
    padding: 5px;
    border-radius: 8px 8px 8px 8px;
}

/* Zoom In #2 */
/*img.publicProductImg {
  -webkit-transform: scale(0.99);
	transform: scale(0.99);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.card:hover img {
	-webkit-transform: scale(1.03);
	transform: scale(1.03);
}*/
.productCard {
  -webkit-transform: scale(0.99);
	transform: scale(0.99);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  
}
.productCard:hover {
	-webkit-transform: scale(1.03);
	transform: scale(1.03);
}

.animate__animated.animate__fadeInDownBig {
  --animate-duration: 1.5s;
}

.animate__animated.animate__slideInUp {
  --animate-duration: 1.5s;
}
.carousel-caption { 
  font-size: calc(3px + 1vw); 
}
.mobile-logo{
  width: 120px;
  height: 66px;
}
.logo-container-mobile{
  margin: auto;
}



.color-theme{
  background-color: #186db7;
  color: #fff;
  padding: 24px;
}

/**Footer**/
.footer
{
  background-color:#186db7;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#d0cccc;
}
.footer .text-justify{
  text-align: justify;
  text-justify: distribute;
}
.footer hr
{
  border-top-color:#d0cccc;
  opacity:0.5
}
.footer hr.small
{
  margin:20px 0
}

.footer a
{
  color:#d0cccc;
}
.footer a:hover
{
  color:#fff;
  text-decoration:none;
}
.footer h6
{
  color:#fcfcfc;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#d0cccc;
  text-decoration:none;
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#fff;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}

.footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#d0cccc
}
.copyright-text
{
  margin:0
}
.footer-powered{
  text-align: right;
}
@media (max-width:991px)
{
  .footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .footer
  {
    padding-bottom:0
  }
  .footer .copyright-text, footer .social-icons, .footer-powered
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}

.social-icons a.twitter:hover
{
  background-color:#fff
}
.social-icons a.youtube:hover
{
  background-color:#FF0000
}
.social-icons a.instagram:hover
{
  background: rgb(64,93,230);
  background: radial-gradient(circle, rgba(64,93,230,1) 4%, rgba(91,81,216,1) 31%, rgba(131,58,180,1) 44%, rgba(193,53,132,1) 58%, rgba(225,48,108,1) 75%, rgba(253,29,29,1) 93%, rgba(245,96,64,1) 100%);
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
  
}


  